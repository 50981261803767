import { useMemo } from 'react';

export const BoxData = () =>
  useMemo(
    () => [
      {
        artPhoto:
          'https://lh3.googleusercontent.com/pw/AP1GczPLsNe9AAMbixuKdV1sgZLGFyD-8-LHyR1ZQTmYCTyD9aYfcsMdgazVOXnc7n6e2Hmsv3AdRDPuubGjzdRHzN4FUS4BR0pAw4CcC9HC-3eE5HistJsglzbWT9r0YPiXIs8LaysslKfxmDCn6KSTB5Su=w1600-h1200-s-no-gm',
        photoAltText:
          'fabric box, made from pale blue died fabric with shells, beads and different textures of fabric sewn on.',
        id: 'beach-treasures',
        description: 'Beach Treasures, 2021',
      },
      {
        artPhoto:
          'https://lh3.googleusercontent.com/pw/AP1GczOU33CxXTSM_E2JZB2cHBTqYDe6XWSVeTYKXr-j0MlQBNi_K-dbe2S6Qo-PCxAAsxOvZsfwdBcN1SpOEwkG1S0oUv3ruFuJDcx16yHzFnJ-dVmXiMaGGL4f68m2VoeOI7czeRIYZhLFSJenhySTYehS=w1936-h1936-s-no-gm',
        photoAltText: 'beach study box',
        id: 'beach-study-1',
        description: 'Beach Study, 2020 - gifted',
      },
      {
        artPhoto:
          'https://lh3.googleusercontent.com/pw/AP1GczMSpuAz0HY1G0pc6IUeXbwKUAGebQId68b72HJ3i04zJWIIhcTvsnegf60_aalZELSjdcncj0HHwPY1ROAhLoaSZxbu4oUr61-jRJbl5ZrGKnxidHsWbciW67oaJPcs3NK8X5Posk-rjruaE_QAyiSj=w2582-h1936-s-no-gm',
        photoAltText:
          'fabric box top with a watery image, sewn on shells, and seagull stitching',
        id: 'beach-study-box-2',
        description: 'Beach Study (detail), 2020',
      },
      {
        artPhoto:
          'https://lh3.googleusercontent.com/pw/AP1GczO9BmG29eTGuZ-7R_Oo3os9bTYvbHob9yez-VqfoCmtCpB4uts43LNMqWeRtWm-x158epwtvklBSApJTXoZBRK2SwU0ggShU3rI6Uvuo--soutQI33AzIQ8JbePQmVG0Ve20QR40k0PnenLqw08waY1=w1936-h1936-s-no-gm',
        photoAltText: 'small square light blue fabric box with a water theme',
        id: 'fish',
        description: 'Fishy, 2020 - gifted',
      },

      {
        artPhoto:
          'https://lh3.googleusercontent.com/pw/AP1GczPmEXzd7z6bqRZVZDd0xvRGmHvl-wYoFV9oYH47c-ufgfhatkiIfQnf2DZB-CPqKTudUP24xnn4heU7GlYw2MqFUcoffLjMsR7HfC1qUYOEglU1biWcRjf7G95Ji_0eNIUF9VfkICbu0rKgkeC_B8CY=w1914-h1936-s-no-gm',
        photoAltText:
          'green and blue silk with embroidery, textured fibres and beads',
        id: 'mixed',
        description: 'Mixed Fibre Arts Study, 2020 - gifted',
      },
      {
        artPhoto:
          'https://lh3.googleusercontent.com/pw/AP1GczMJjqRr8VzGfWKoV6A9uzTebOySI7bUXOVYNfNdh3HBH3XJElfgVyo293qzSpUhel1qUgDZeedzBJCd8wh-DRoRAn5RM9tpo9vZ3bWkGMxW-teuHgBzA-2bzoCYh_N714aDHvnD_y68uAe0kfIWJYgK=w1936-h1936-s-no-gm',
        photoAltText: 'Mixed Fibre Arts Study detail',
        id: 'mixed-detail',
        description: 'Mixed Fibre Arts Study (detail), 2020 - gifted',
      },
      {
        artPhoto:
          'https://lh3.googleusercontent.com/pw/AP1GczO0ov-Lh7JgBB0ke3vDRBU5NQl3xKV17zf4PKdGn7w20JIHj9OpTx7IxuqvAf4J9Be2-d98f50i_GgY6PGPZLTq4eX6Mm9CVpQRHugHvKvwhbXhsT0TVD885u2dqB0cquB0s-207ZL4nsjGq53bRL0p=w2592-h1936-s-no-gm',
        photoAltText:
          'Brown fabric box with crinkled blow fabric and beads embroidered on the top, and the words “into life is changes” on the front',
        id: 'stones',
        description: 'Quotation Series - Stones and Water, 2020 - gifted',
      },
      {
        artPhoto:
          'https://lh3.googleusercontent.com/pw/AP1GczPaJiB6spfZrjhpbMPRQEEgQsGqUtXS8W1_Z2S4MML0-NSj-GQGxiA8mUPGT_Vooq1o3FV1YDHplC5emamSre2aj4uRbHgBUdKZIScJfOuo2GSQhQjis1Uqz48angwLfx_VUffXohK1lUM_oCuZXKPw=w738-h465-s-no-gm',
        photoAltText: 'turquoise and gold fabric box with beads sewn on',
        id: 'indian-box',
        description: 'Indian Heritage, 2019, gifted to Rachel Sakhuja',
      },
      {
        artPhoto:
          'https://lh3.googleusercontent.com/pw/AP1GczNMrRXjoBAQa36SAjcV0BXpVSLDZiQlvRAWHBOhoJezbP1AsX8MeGiWczxzY1dzGhAzNvfKSJnKJx7xhNO2K5HTu0Xkz_DINjrxaGi0N_P69A60uWBY6FqNYyotzFG7DwFlBivXVql4Xqy5rc3pG_8m=w958-h750-s-no-gm',
        photoAltText: 'Indian Heritage box detail',
        id: 'indian-box-detail',
        description: 'Indian Heritage (detail), 2019',
      },
      {
        artPhoto:
          'https://lh3.googleusercontent.com/pw/AP1GczMWd7xLYkl9O7iZMGz_oNJYU-CsmQsza4fYEh5Pt4kDsVTA1q0DO4TBuVjDLzYbtE6K20ueYuqP5ebk8crPg6NCv-yyhcWP0Rs-aRKRcrpqnYOJheDttoAF2uG3HLj0wiUI-4OI689gL9a2iNhrg-em=w2582-h1936',
        photoAltText: 'a group of colourful fabric boxes',
        id: 'box-making-workshop',
        description: 'Box making workshop - student pieces, 2019',
      },

      {
        artPhoto:
          'https://lh3.googleusercontent.com/pw/AP1GczPchuNd43ug8v3rDuroWEjypNa7chvheusS3GjmCu5geVEzJzD5JZwmbBn5-HFqpVNAUVBwuDOSd7fS3AbmWS_J7RSm-yzCJw2xec3WO5F3Zh0IlDnw1pc7m2cV4ZUrLfTl-z19o8kT00db4I5cp0FT=w747-h551-s-no-gm',
        photoAltText:
          'black velvet box with cotton pebbles and orange yarn and beads embroidered in, bronze coin-like button',
        id: 'pebbles',
        description: 'Pebbles in Water, 2018',
      },
      {
        artPhoto:
          'https://lh3.googleusercontent.com/pw/AP1GczOZKm9DR_TD8_IyG7cJH6QGtjqqPpvu5TjX-Rdd19yKw9y8BUwm0cE4JcDA5zG7NpUbOKvPjp5hvRyzjzUQAiELZPyQTEBQrCBIXvbID5QSqmhIc9Qi14q6vAkMQDoErlUQP5oLQrTw6vPac235p0R6=w2188-h1936-s-no-gm',
        photoAltText:
          'yellow fabric box with different shades of green bunched fabric, shells and embroidery. ',
        id: 'sun',
        description: 'Sun on Water, 2018 - gifted',
      },
      {
        artPhoto:
          'https://lh3.googleusercontent.com/pw/AP1GczM6tcemyPHtT_EpupyDwaLDR_VOTV4JrxDlavcSv_qTndvyhFUyiQGXfYGKI3ot2oHnGy13QdopfQj4GvPzYVJYdVR8_PjLmT3wgtGMKCZgxl8NLmJoQuV8E_MvtkuYDyqSbCF3vRrfZxSnbHgR81U5=w744-h762-s-no-gm',
        photoAltText:
          'five fabric boxes embroidered with beads and fibres, from top left, pink, middle left, peach, bottom left light brown, top right blues with red and yellow highlights, bottom right, brown with blue highlights',
        id: 'quotation-series',
        description: 'Quotation Box Series, 2015 - all gifted',
      },
      {
        artPhoto:
          'https://lh3.googleusercontent.com/pw/AP1GczP6jIGgieD1BlT-bLSTibFM97x-6jOMTQpn4tg2tBRNDxGzsIyUvudcXHPCqa2nQ0DVykbWJxqmN319scl_zo28u8AJqIXUX2NKzr-6IXhJm-FyrJqLM4hrSY_LXSxw1FvRMWcRU27IRRG47w8f8vi7=w2592-h1936-s-no-gm',
        photoAltText:
          'peachy fabric box with gold highlights and beads and organza embroidered on, impressionistic images of flowers',
        id: 'quotation-box',
        description: ' Quotation Series - Garden, 2015 - gifted',
      },
      {
        artPhoto:
          'https://lh3.googleusercontent.com/pw/AP1GczNTPXaRJxRTDvX9lhY7_mNCRIG7olWk0_vkjOmEWrAIyYtUn7bEgiFmMh5xJra49Yfm_tfWet2NDoT8y4g8loHzkK3SWrLNirn1D_WatRVvJvtb-gjO-0aQXJ8ll7qXrzEXOXQQYhBilzzRXSm05Vg8=w2592-h1936-s-no-gm',
        photoAltText:
          'Inside of a fabric box, green, gold and peachy with a small card that says “Your Box Says: “Let imagination Take you to The deep places Of your soul”',
        id: 'quotation-inside',
        description: 'Quotation Series - Garden (inside) ',
      },
      {
        artPhoto:
          'https://lh3.googleusercontent.com/pw/AP1GczOCHllYxXssXzpvf2dheOXrRtEbj_zbRNuYsiwOk1eIoEFzclMDYpbM1jSjah6MFszZD3H-r5-nBxnkXMNRUpFROKJGqfXNRXCXEuU7ddxJ0OkWEDy34KdOmVIkIe5-8jx5weiLkEzZ_8WR80GWv8JX=w1759-h1662-s-no-gm',
        photoAltText: 'Elephant and Bubble box',
        id: 'elephant-bubble',
        description: 'Elephant and Bubble , 2014 - gifted',
      },

      {
        artPhoto:
          'https://lh3.googleusercontent.com/pw/AP1GczMpfZWagjh2FcI2Fo2qh-xTBF2cV3gzxyet6p6cguXE-7P_uHbpWFnR4fcCQYBEjOoKu3V4lI_YrxngjYh1tfJz2JNN4TzT4eyn4nu6HWuojLt84yKrau7rhUPY1vIODuFCrCQ35Alfqwk6iIc0YEXf=w662-h736-s-no-gm',
        photoAltText:
          'fabric embroidered box in blues and yellows with the image of trees, a lake and a sunset on the top, and the words “The Magic of Nature” on the front panel',
        id: 'magic',
        description:
          'The Magic of Nature, 2013 - wedding gift to Becky Butler and Dyan Hatanaka',
      },
      {
        artPhoto:
          'https://lh3.googleusercontent.com/pw/AP1GczPrX1jMSSuq1YvPQhQ-TH4uVYPbrR4EtSkvVMJ8Q0x3j_sBjkZ9-afEgwxz3yrd9Y0Vm9qldOE8O6P0dwwbsNYd7M7iKsT682i-dHGUeLdds4xV69CzuBOH5Ug4qRvVZ8aB9wTSBUYsIAjFQrQygmlF=w2592-h1936-s-no-gm',
        photoAltText:
          'fabric box with an image of the countyside, a house and gulls',
        id: 'country-side',
        description: 'Country Side - gifted to Adita Gupta',
      },
    ],
    [],
  );
