import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Loader, pxToRem } from '../../../utility';
import { breakpoints } from '../../../styled-theme';

export interface PageError {
  message: string;
}

interface PageWrapperProps {
  readonly isLoading?: boolean;
  readonly error?: PageError;
  readonly children: React.ReactNode;
  readonly bgImg?: string;
}

interface BaseContentWrapperProps {
  readonly children: React.ReactNode;
}

const BaseContentWrapper: React.FC<BaseContentWrapperProps> = ({
  children,
}) => {
  return (
    <BaseContentContainer>
      <ContentWrapper>{children}</ContentWrapper>
    </BaseContentContainer>
  );
};

export const PageWrapper: React.FC<PageWrapperProps> = ({
  isLoading,
  error,
  children,
  bgImg,
}) => {
  const { t } = useTranslation();

  if (error) {
    return (
      <BaseContentWrapper>
        <ErrorPageHeading>
          {t('Whoops! Something went wrong.')}
        </ErrorPageHeading>
        <ErrorMessage data-testid="error-content" data-cy="error-content">
          {error.message}
        </ErrorMessage>
      </BaseContentWrapper>
    );
  }

  if (isLoading) {
    return (
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    );
  }

  return (
    <BackgroundImageContainer bgImg={bgImg}>
      <BaseContentWrapper>{children}</BaseContentWrapper>
    </BackgroundImageContainer>
  );
};

const BackgroundImageContainer = styled.div<{ bgImg?: string }>`
  display: block;
  background-image: url(${({ bgImg }) => bgImg || ''});
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
`;

const BaseContentContainer = styled.main`
  width: 100%;
  margin: 2.5rem auto;
  padding-top: 2.5rem;
  max-width: ${pxToRem(1400)};

  @media (min-width: ${breakpoints.lg}) {
    margin: 3.5rem auto;
  }
`;

const LoaderContainer = styled.main`
  width: 100%;
  margin: 0 auto 4rem auto;
  padding-top: 2rem;
  max-width: ${pxToRem(2000)};
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: ${breakpoints.md}) {
    padding-top: ${pxToRem(30)};
  }
`;

const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 1rem;

  @media (min-width: ${breakpoints.xs}) {
    padding: 0 2rem;
  }

  @media (min-width: ${breakpoints.lg}) {
    padding: 0 4rem;
  }
`;

const ErrorPageHeading = styled.h2`
  margin-top: 1.5rem;
  font-weight: 400;
  line-height: 1;

  @media (min-width: ${breakpoints.md}) {
    margin: 0;
  }
`;

const ErrorMessage = styled.p`
  overflow-wrap: break-word;
`;
