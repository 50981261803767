import { useMemo } from 'react';

export const SculptureData = () =>
  useMemo(
    () => [
      {
        artPhoto:
          'https://lh3.googleusercontent.com/pw/AP1GczO-26Leu6dtJ0fF5A02yLrvIVk4j6mA54nVnhdARikuuHYVxzXe0uxzgYwdaF2I2Z_EPdVQgz9aLyFiV1D9smP0zGkq5U17NH009awH-81LxKYZgCLMDipvimcp1HSdEHoJkk5s_hQJk9fxjEtvN7R-=w842-h1288-s-no?authuser=0',
        photoAltText:
          'Thin hanging sculpture made with copper piping and turquoise fabric flowers and turquoise organza against a white fabric background.',
        id: 'whimsy-from-under-my-kitchen-sink',
        description: 'Whimsy from Under my Kitchen Sink, 2024',
      },
      {
        artPhoto:
          'https://lh3.googleusercontent.com/pw/AP1GczPwHJND1cyork8FdZ0VbEiZUjyXVkYJCdOgrQaVAkMPK8DG7uvEtmHlBt8sHqOx7CBXlrLgZ-jh46i4SKCbtdZhZSpAQPLAZovOTpYAwwp-gODQ-VoOpcn6sGN8cG2dpFoCROdg4uCsUwhmJhiDHuVU=w990-h1484-s-no?authuser=0',
        photoAltText:
          'Sculpture of a dancer made of a small long piece of drift wood, light blue fabric, beads and yarn against a black background',
        id: 'driftwood-dancer',
        description: 'Driftwood Dancer, 2020',
      },
      {
        artPhoto:
          'https://lh3.googleusercontent.com/pw/AP1GczOmEn5vge0i62o2uH0ntBtFH9-nOU6Ul5WiF7PZgMCuoq_NCfEj_F1qTKI1tdOYcW7uCUFP7SvjOXugMZq99NHqri-Z8y4NhgnDoBPJKQjjZQ-UN7YZzubCH3x5iNpXtllAyymYC-mV_h25Rwcu7rGS=w1114-h1484-s-no?authuser=0',
        photoAltText:
          'Three dimension fabric sculpture - two rectangles with a hand on top pointing up, grey with red, orange, gold, purple and blues against a white background on a brown wooden table',
        id: 'the-shortlist-1',
        description: 'The Short List (companion to a poem) 2001',
      },
      {
        artPhoto:
          'https://lh3.googleusercontent.com/pw/AP1GczNVdQWngINU2MmW5dmse7nN5yjl6jb--np8f4GT7d45-AILZLzA21js1L7fClU3hNMdgk6l94MwjKOjbjI-gGbtN7i7lUMzCxybIbTXgQvEAncl1vajT-x5bwDN9D2BrdNFVT0Rd71-DC5liYBrf_8p=w1114-h1484-s-no?authuser=0',
        photoAltText:
          'Three dimension fabric sculpture - two rectangles with a hand on top pointing up, grey with red, orange and blues against a white background.',
        id: 'the-shortlist-2',
        description: 'The Short List, 2001 - detail',
      },

      {
        artPhoto:
          'https://lh3.googleusercontent.com/pw/AP1GczPCJeJihhiT1CrEYpo7-iN9-ljmAs-c6sKDeQGMDJujIBA4G2oB1WFZI_MZnW2gEbys1fQVNkmt8AYMggOnzkfdzrDMYWGXJt-Gx5p41h6uX8aDFuQ7fHq3xGb1FlQYVwQlluF1UQLJ2sNlQh6IU9DE=w1684-h1122-s-no?authuser=0',
        photoAltText:
          'Three dimensional colourful fabric sculpture with brass pipe, embroidered flowers and organza, reds, yellow, purples, whites, blacks, oranges, greens on a dark blue background',
        id: 'have-we-partied-too-hard',
        description: 'Have We Partied Too Hard in the Garden, 2016',
      },
      {
        artPhoto:
          'https://lh3.googleusercontent.com/pw/AP1GczOnwhcuwyqEDqm_i1J8JE1-woOE_o_2PBQ-VJ_fpOeRspZ-IlOQcrn9uYPnHx88YkcLCV5zOMLKGYUspKIBui0gfkYot2TdS-5tfjuVoMQSR08tRo19ZKNGP9E3kfTtTvJztNy-_auTvZlUfD_e6mMu=w1484-h1484-s-no?authuser=0',
        photoAltText:
          'Bright green monster puppet with a purple moustache, eyebrows on a dark blue',
        id: 'mixed-detail',
        description: 'The Fart, Comissioned by Robert Munsch, 1992',
      },
    ],
    [],
  );
