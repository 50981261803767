import React from 'react';
import styled from '@emotion/styled';
import { PageWrapper } from '../components';
import { StyledH2, StyledParagraph, StyledSpan } from './pages.styled';
import poetryBg from '../assets/backgrounds/poetry.png';

export const PoetryAndStories = () => {
  return (
    <PageWrapper bgImg={poetryBg as string}>
      <StyledH2 color="white">
        <StyledSpan> Poetry And Stories </StyledSpan>
      </StyledH2>
      <TextWrapper>
        <StyledParagraph color="white">
          You can find videos of me reading my poetry and sharing stories on my{' '}
          <StyledAnchor
            href="https://www.youtube.com/channel/UCuxKlbbK0gH-mkSwv5z5gOA"
            rel="noopener noreferrer"
            target="_blank">
            YouTube channel
          </StyledAnchor>
        </StyledParagraph>
      </TextWrapper>
    </PageWrapper>
  );
};

const TextWrapper = styled.section`
  margin: 0 auto;
  text-align: center;
  max-width: 720px;
`;

const StyledAnchor = styled.a`
  color: white;
`;
