import styled from '@emotion/styled';
import React from 'react';
import { Link } from 'react-router-dom';
import { dianasTheme } from '../../../styled-theme';

export const PageFooter = () => {
  const jumpToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <FooterContainer>
      <StyledButton type="button" onClick={jumpToTop}>
        Back to Top
      </StyledButton>
      <StyledLink to="/">Home</StyledLink>
    </FooterContainer>
  );
};

const StyledButton = styled.button`
  color: black;
  font-size: 1.25rem;
  font-family: BadScript, sans-serif;
  font-weight: bold;
  font-weight: 500;
  border: none;
  background-color: transparent;
  cursor: pointer;

  &:hover,
  :focus {
    color: #e35129;
  }
`;

const StyledLink = styled(Link)`
  color: black;
  font-family: BadScript, sans-serif;
  font-size: 1.25rem;
  font-weight: bold;
  font-weight: 500;
  border: none;
  background-color: transparent;
  cursor: pointer;

  &:hover,
  :focus {
    color: #e35129;
    text-decoration: none;
  }
`;

const FooterContainer = styled.div`
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  background-color: ${dianasTheme.backgroundColor};
  width: 100%;
  padding: 0.5rem 2rem;
  border-top: 0.063rem solid darkgray;
  bottom: 0;
  left: 0;
  right: 0;
`;
