import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { Blurhash } from 'react-blurhash';

interface ImageComponentProps {
  artPhoto: string;
  photoAltText: string;
  isLoaded: boolean;
  setIsLoaded: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ImageComponent: React.FC<ImageComponentProps> = ({
  artPhoto,
  photoAltText,
  isLoaded,
  setIsLoaded,
}) => {
  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      setIsLoaded(true);
    };
    img.src = artPhoto;
  }, [artPhoto, setIsLoaded]);

  return (
    <>
      <PlaceholderContainer isLoaded={isLoaded}>
        <Blurhash
          hash="LEHV6nWB2yk8pyo0adR*.7kCMdnj"
          width={340}
          height={340}
          resolutionX={32}
          resolutionY={32}
          punch={1}
        />
      </PlaceholderContainer>
      <StyledImage src={artPhoto} alt={photoAltText} isLoaded={isLoaded} />
    </>
  );
};

const PlaceholderContainer = styled.div<{
  isLoaded?: boolean;
}>`
  display: ${({ isLoaded }) => (!isLoaded ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 100%;
  object-fit: cover;
  margin: 0 auto;
`;

const StyledImage = styled.img<{ isLoaded?: boolean }>`
  display: ${({ isLoaded }) => (isLoaded ? 'block' : 'none')};
  max-width: 100%;
  object-fit: cover;
  margin: 0 auto;
  border: solid 0.25rem #444444;
`;
