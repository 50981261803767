import React from 'react';
import { Gallery, PageWrapper, PageFooter } from '../components';
import { StyledH2, StyledSpan } from './pages.styled';
import { BoxData } from '../assets/data';

export const FabricBoxes = () => {
  return (
    <PageWrapper>
      <StyledH2>
        <StyledSpan> Fabric Boxes </StyledSpan>
      </StyledH2>
      <Gallery data={BoxData} />
      <PageFooter />
    </PageWrapper>
  );
};
