import { useMemo } from 'react';

export const WallHangingData = () =>
  useMemo(
    () => [
      // {
      //   artPhoto:
      //     'https://lh3.googleusercontent.com/pw/AP1GczMaQEPx50btP1SMnsmenzzREZm_D4p5y63cAoaZErUvsxu-9R4ZQi8egweJQ_GrlM7p5tumY0rE3IJpx-eFDNWY4bbVkhiWIbJCAo39skzqz-2AT2R45v3dT7d8lcXo5fhKFKTi9dvJ_dWZHgJgLa-d=w1452-h1936-s-no-gm',
      //   photoAltText:
      //     'Photos of a series of incomplete mixed media fibre arts hangings, all in blues, browns and greens, featuring driftwood, appliqué and embroidery.',
      //   id: 'water-studies-works-in-progress',
      //   description: 'Water Studies (works in progress), 2024',
      // },
      {
        artPhoto:
          'https://lh3.googleusercontent.com/pw/AP1GczMymruAg0iwjUmkgGoRs6LGMfYNNSa21VNObxLnwLuDFyquodbRBBCYoJmkXhu8oxV2hpFdHlfKAk3KenF5CNykitB6D6yj3GfNXXI8uXdXgKxmC1Frp7hI6xJwp7Ug9Br4KdmRs56K7x3yWkv2aaJZ=w1452-h1936-s-no-gm',
        photoAltText:
          'Photos of a mixed media fibre art wall hanging with an appliquéd green/blue fish in the top left, a piece of drift wood centre, and appliquéd pale blue wave running across a shiny green piece of fabric with a grey fabric frame.',
        id: 'water-study-two',
        description: 'Water Study Two, (works in progress), 2024',
      },
      {
        artPhoto:
          'https://lh3.googleusercontent.com/pw/AP1GczPObWvmfQpfDlapy6P1qIuXtdzv4gbKEqi6sp0t6JFXdG4etxmucakGv-Mh0Opa02G0y0nKOjREYOMxobhDBLHuykViFqgEL9CeELXBFDimI66m6khYRQV3nWdEs71KA9x2aKeq6M6tSgNoClAH8jwx=w1314-h1936-s-no-gm',
        photoAltText:
          'Photo of mixed media (appliqué, embroidery, beading) fibre arts vertical wall hanging, depicting the black silhouette of a fisherman on blue-grey shiny water in the foreground, an embroidered fisherman in the background, a jumping fish (made of white coral bead), rolling hills and a setting sun in the background.',
        id: 'weeping-1',
        description: 'Weeping for Myanmar, 2022',
      },
      // {
      //   artPhoto:
      //     'https://lh3.googleusercontent.com/pw/AP1GczPV4Ci6IpwHuNIRjKqzq6PqwUL0dhBNHX_ugE4eFBbOENdfm_1Ba5mJA_nBFQmX16Ltsi2azSXuxe1S4fvUhMZ5JR4dD52ChqW6Q4Yqa3rJpzmJH3Y8YSrOAfsYc4MATD-IuCxmOHDrxjR3QoLDK8BE=w1452-h1936-s-no-gm',
      //   photoAltText:
      //     'Photo of the top left corner of a mixed media fibre arts piece including colourful yarns, beats and embroidery depicting the “melting” corner of a fabric frame.',
      //   id: 'weeping-2',
      //   description: 'Weeping for Myanmar (detail top), 2022',
      // },
      // {
      //   artPhoto:
      //     'https://lh3.googleusercontent.com/pw/AP1GczOemQKc0jdKSq3HIDtX7_CZfEv01gA6yN7GDwGhj8FJ60LIB3bye5XCCPEGj1Hw2p6iXZRztmVym22DjaibPkSKizu49O_1ism51C4Juff69WlUgYL3YgOQe4nP2cNTxTXg50qZ-bx1vTRhKvw9cStQ=w1452-h1936-s-no-gm',
      //   photoAltText:
      //     'Photo of the bottom right corner of a mixed media fibre arts piece including colourful yarns, beats and embroidery depicting the “melting” corner of a fabric frame. ',
      //   id: 'weeping-3',
      //   description: 'Weeping for Myanmar (detail bottom), 2022',
      // },
      {
        artPhoto:
          'https://lh3.googleusercontent.com/pw/AP1GczNEbs_A4fOtgO3PCoEM75q2UMt7UI0LzCBhCk39TLJagEPV6qcqreAPYuj_QNh8Hl8JW6j7rNDoVWN9mukHYCkZ_5ORdhvPJV-trNcJvb1sTyjIneyjbMhQOCz5w6GgjslBS9euFnx2g6YuPdG0zv-3=w2578-h1934-s-no-gm',
        photoAltText:
          'Photo of a colourful horizontal quilt with the word “Odin” in the centre. Made with  a series of concentric rectangles in animal and plant print fabrics of yellows, blues, browns and greens.',
        id: 'odin-1',
        description: 'Odin, 2022, gifted to Odin',
      },
      // {
      //   artPhoto:
      //     'https://lh3.googleusercontent.com/pw/AP1GczP46WisdDgt2SjD32SQKa3p7FaZmYej-3v1WSj-5zaCo9OW8N3CZx3OU2NUz3vTw6Xm6P2TP6zazxdiSA2RX5zEklkmYCGTHFhHunmNRbLPemL1dF3JajwhlB_97h5VdhG16XN8kCKbf9bCMQFILbWG=w1452-h1936-s-no-gm',
      //   photoAltText:
      //     'Photo of blue, yellow and white patterned quilted fabric with a yellow dragonfly embroidered on the middle panel',
      //   id: 'odin-2',
      //   description: 'Odin (detail), 2022',
      // },
      // {
      //   artPhoto:
      //     'https://lh3.googleusercontent.com/pw/AP1GczOcIrvqFDX5j38bJkJHwOhDqgoFmSOiy1ALGooyFM9u0lalLIjGNHaY0iCB278UexKkVB1n0Lns0YyvQiW4lful06Mam1cGVS0tu1hKq7diPVBhXIimBNl8FzK3bcawotmRShPd1fRqoTeaok7I9a7G=w1452-h1936-s-no-gm',
      //   photoAltText:
      //     'Photo of blue, yellow and white patterned quilted fabric with three orange ants and a smaller multicoloured ant embroidered on the middle panel',
      //   id: 'odin-3',
      //   description: 'Odin (detail two)',
      // },
      {
        artPhoto:
          'https://lh3.googleusercontent.com/pw/AP1GczOEHDrqMYsHBNcReJClNjirTv95k3aBt-90jWOuJE3JxfIfrMMSJLfYNqlfjAMq5xJ9jYGT3rlQNEc7UjsOP-Hj6Q2tsT2kqCSJ0zAAm99FXS-u7FwlV5Z5Qb7f1ICEnESPJObuRI4JlSliWV7R02s9=w1452-h1936-s-no-gm',
        photoAltText:
          'Photo of blue, yellow, green and white patterned quilted fabric with a ladybug embroidered on the middle panel',
        id: 'odin-4',
        description: 'Odin (detail)',
        // description: 'Odin (detail three)',
      },
      {
        artPhoto:
          'https://lh3.googleusercontent.com/pw/AP1GczNuh2SUh7DT4rvjzqpYDMwDyGwYUyc6HYCUrTSHfM5ac-naqvjssV0w3P-U6e6zPBkIDOnXUh6C0rpqn6xrCwirBWeg3HOAsYL9_D_ClIFbXdrtj8geUHsjA9A73Uhyn0QVQPXp40bbbTQS6ccl87y6=w1936-h1936-s-no-gm',
        photoAltText:
          'Photo of a mixed media fibre arts abstract wall hanging, including textured fabric, wooden beads, drift wood, textured brown fabric, and organza in blues, beiges, and browns.',
        id: 'water-play-one',
        description: 'Water Play One, 2021',
      },
      {
        artPhoto:
          'https://lh3.googleusercontent.com/pw/AP1GczO7qqpuD54Wumq_PDNAy1dzdPEVN4UTFqM34N9c-hhjjNDXjBTZnPsPH_MqnaQK2yeBNRPF_WidMCkFc_N95abohsORoX7W0_YHu25hriIHcjVtfMtlXma5Uq8LTG627uJbgK7kiIPvYXzRQAvNHqV4=w2578-h1084-s-no-gm',
        photoAltText:
          'Photo of a green fabric sign with gold sheen with the appliquéd words “Cafe Mandalay and appliquéd gold birds and gold  temple hanging from a piece of driftwood.',
        id: 'cafe-mandalay',
        description: 'Cafe Mandalay (sign), 2020',
      },
      {
        artPhoto:
          'https://lh3.googleusercontent.com/pw/AP1GczOBrw3EA-Jw4hjhAdAw-6LMD5tvmvwV9aAwpAqK6Yuppebj0KfhI4m6bFyFL-v8rNfs2eHZ0FbVGSYWPahGo7sVsnBInbUYI7tWJDtYP4dsymVo1PznB_B8_VczaVhnDj0a3mmnNRCPDDKTxtBRzFfq=w2578-h1718-s-no-gm',
        photoAltText:
          'Photo of a very colourful heavily embroidered and appliquéd wall hanging with a heart theme hanging from a piece of drift wood with blue transparent ribbons on a black background.',
        id: 'journeys-of-a-stitching-heart',
        description: 'Journeys of a Stitching Heart, 2019',
      },
      {
        artPhoto:
          'https://lh3.googleusercontent.com/pw/AP1GczP0lT9LOsTxct4UX2LBx5107xSuZeyS_netdcM2BV0-vLsHdrcUabp_3zsCg_RUZ84mtQibGGRmPqSa5y8sBnStAlWHUnmflTcteuZ65UvBKA79Knqpb31YDMtd-XcyUKzAXCUyREMA6Eqh8ATjhE4x=w1292-h1936-s-no-gm',
        photoAltText:
          'Photo of a vertical mixed media fabric wall hanging against a brick wall on a piece of drift wood. Shiny blues, browns, pinks and peach fabrics depicting water and sand, with beads, and shells, and stuffed fabric and wired organza sewn on to depict mermaid tails and breasts poking up through the water.',
        id: 'mermaids-1',
        description: 'Where Mermaids Frollick, 2018',
      },
      {
        artPhoto:
          'https://lh3.googleusercontent.com/pw/AP1GczNdENLtg6vSKanffz7Jb59QOZslVZkYsASKNWctxX3mdF9I3b-NfHbG4gLWrBCpc54XnwQxT5nGIG7GeOvf-AwhRpo2ldv_b39hQ5Riugd17a8n65zba7zE7g334Pw1dzFXRvVxPAvZk4RZMhSMPEvq=w1452-h1936-s-no-gm',
        photoAltText:
          'Close up photo of a fibre arts piece with light brown fabric, flowing lines, shells, shiny plastic beads and yarn sculpture texturing the surface',
        id: 'mermaids-2',
        description: 'Where Mermaids Frollic (detail), 2018',
      },
      {
        artPhoto:
          'https://lh3.googleusercontent.com/pw/AP1GczNRtDgmycZooHskkCo5JC_yOgPwXYeaTR0ehe-CwjyyT1JfG7x60YD191CuBlxlDqOY_9OibkcZYvC6CEH_7xaH5bEzG3CBCJLCinnMqaaMy3FxGXwbbxZK2OPPnCytMy6CBPnJ2JRzaWI5J3WKaFoZ=w2578-h1934-s-no-gm',
        photoAltText:
          'Close up photo of a fibre arts piece with light blue fabric, flowing lines, wired pinky/purple organza depicting mermaid tails and sculptured blue and peach fabric depicting mermaid breasts coming up out of wavy water, surrounded by colourful embroidery and beading depicting water sparkles.',
        id: 'mermaids-3',
        description: 'Where Mermaids Frollick (detail two), 2018',
      },
      // {
      //   artPhoto:
      //     'https://lh3.googleusercontent.com/pw/AP1GczOksU26H6PIzNvQgzayZKcGNN02QgtD78aSliCVBe6WGNlulAyic4xz1dOzMvr_V3kQ1VoEPgZQHF-jrIklcdTtNj9PJiCAmFlbKQIeFQIGIct_2Cj_6_HBb8JngoZx7UTsh147u1tCt0k-kgop9lcG=w2578-h1934-s-no-gm',
      //   photoAltText:
      //     'Close up photo of a mixed media fibre arts piece with different textures of fabric, an acorn, a stone a piece of drift wood, copper wire and various types of stitching in greys, greens and browns.',
      //   id: 'undergrowth',
      //   description: 'Undergrowth (detail), 2018',
      // },
      {
        artPhoto:
          'https://lh3.googleusercontent.com/pw/AP1GczMGsyKs7A0FYGBmeBgcNr8O5fArc4JLyggN1GLGHFWJ0AKDPIGER_C2srpHM6M7FySRloOeBk8SdueobyEcI13aYHdEWEAsfh5c0PPEakeYzfALdBCDUe2GxDmRMV5W4uEslVtkG5tsBdy1YHB27J0B=w2578-h1718-s-no-gm',
        photoAltText:
          'Photo of fabric appliquéd sign that says “Shanti” with red lettering on a black background with gold ornamenting and a red, purple and gold border and elephant and camel beads sewn on.',
        id: 'shanti',
        description: 'Shanti (sign), 2018',
      },
      {
        artPhoto:
          'https://lh3.googleusercontent.com/pw/AP1GczOPAYssvhISTjkUgFHXTJpsQrkgaen6T96V3ufW9RM9RIR2vGnccDgU_eESLUdG-0pKp-2P9x-J0cxdvW1e--hYJsraP7M6RA3bBqQbwXkkO7hp4YFDzo4U54BloOXCmQ---RD5yoC1VjVDTp9s9acv=w1290-h1936-s-no-gm',
        photoAltText:
          'Photo of a mixed media fibre arts vertical landscape with the depiction of a large tree trunk in the foreground, a mossy green hill, white fence and pale blue sky in the background, and many buttons and white organza sewn on depicting first snow.',
        id: 'snowing-buttons',
        description: 'Snowing Buttons, 2016 ',
      },
      {
        artPhoto:
          'https://lh3.googleusercontent.com/pw/AP1GczOGS05ZVw5Wg1QTWxecKf4xFbhwPQt-6CXP97JzE97ySYIdbiVsY46foighhZ57VFhHY_hmzvdmdutij4Pid5ZyCYcZu9bc_sMsV0xxKmntzowybbgSz-aC-3Gl0XGpOzN_OKFjeOEScbPNxzUiKbMM=w2578-h1718-s-no-gm',
        photoAltText:
          'Photo of mixed media (appliqué, embroidery, beading) fibre arts horizontal wall hanging, depicting the black silhouette of a fisherman on blue-grey water in the foreground, a jumping fish (made of white coral bead), rolling hills reds, and green hills with coral elephant beads and a bright red setting sun with coral bird beads in the background.',
        id: 'memories-of-myanmar',
        description: 'Memories of Myanmar, 2015',
      },
      {
        artPhoto:
          'https://lh3.googleusercontent.com/pw/AP1GczOxt0tVaSO5A3L47c-8n9tJlO5QibNsubkMXs5zXKzbDVOl4e-3XH4vP-ReD9xMB97bmPSIe7e_xjxu8E-bu3LnWxPJaJUd-w53Y6TaF2-lshhc4lUX_V7tt4BA4F_faeCo-zvxgTqZ3tD9Y4NKZAtp=w1290-h1936-s-no-gm',
        photoAltText:
          'Photo of a mixed media (embroidery, and appliqué) fibre arts landscape on a black background with a vertical orientation, grey rocks and grasses and the image of a woman’s body from the perspective of a woman looking down with the curves of breasts, stomach and legs depicted as a continuation of a rocky island landscape. A lake and three more islands and sky in the background. Colours: blues, greys, greens, browns and peach. ',
        id: 'my-landscapes-2001',
        description: 'Memories of Myanmar, 2015',
      },
      {
        artPhoto:
          'https://lh3.googleusercontent.com/pw/AP1GczNwh31566YZqJesmYqdT58A-QoCjUxdJrL8MDU2aInTSUKNM0hWszISdC_SUykkKsHo8KmMPn8THvwugmlGeW3dHY5ox0WTpLaN_Z-tfkKq7zU2sBEk9Pac9rUWR9elSTGWEJtnwEzpSl-t16vMy_AS=w968-h968-s-no-gm',
        photoAltText:
          'Photo of a fabric wall hanging of a large tree with various shades purple and green leaves, standing by a river with silhouetted people of different races standing under and rolling hills of browns, greys and purples in the background, and a light purple sky.',
        id: 'the-whole-people-of-god',
        description:
          'The Whole People of God, commissioned for Saint Georges United Church, Toronto, 1988',
      },
      {
        artPhoto:
          'https://lh3.googleusercontent.com/pw/AP1GczPOl7SM5E7_c0IecGyhiChsPFYmbHqbGLEV4vtj7Hzmgk3Ixsq3qLFp-eyCsn60pY0l-mEy5XlwquRTr0MfDHCf0iNrj9NlvCK803H0jpKOZOIG1nu8do7ffLZ1LhUT2BFQHIVPVky9wDiYHUxGme0z=w2240-h1936-s-no-gm',
        photoAltText:
          'photo of a mixed media fibre arts landscape (embroidery and appliqué) of a river, stones, bull rushes, bare rolling hills and sky in the background in browns, blues, greens and greys',
        id: 'landscape-study-1988',
        description: 'Landscape Study, 1988',
      },
    ],
    [],
  );
