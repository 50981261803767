import styled from '@emotion/styled';

export const GalleryWrapper = styled.ul`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0rem 2rem;
  width: 100%;
  margin-bottom: 3rem;
`;

export const ImageContainer = styled.li`
  overflow: visible;
  list-style: none;
`;

export const StyledImage = styled.img`
  display: block;
  max-width: 100%;
  object-fit: cover;
  margin: 0 auto;
  border: solid 0.25rem #444444;
`;

export const Description = styled.p<{ isLoaded: boolean }>`
  display: ${({ isLoaded }) => (isLoaded ? 'block' : 'none')};
  color: black;
  text-indent: 0;
  font-family: BadScript, sans-serif;
  font-size: clamp(1.25rem, 3vw, 2rem);
  font-weight: 700;
  text-align: center;
  margin: 2rem 0;
`;
