import React from 'react';
import contact from '../assets/backgrounds/contact.png';
import { PageWrapper } from '../components';
import { StyledH2, StyledSpan } from './pages.styled';

export const Contact = () => {
  return (
    <PageWrapper bgImg={contact as string}>
      <StyledH2>
        <StyledSpan> Bio </StyledSpan>
      </StyledH2>
    </PageWrapper>
  );
};
