import { useMemo } from 'react';

export const PracticalArtData = () =>
  useMemo(
    () => [
      {
        artPhoto:
          'https://lh3.googleusercontent.com/pw/AP1GczM9CAxOrh39NsvbiqCHZnbu2IS91QWkkPCnW2NBeEZc98j5bK_3F8606IASpJDaQi98rCcMefEU4CYkBR6fdoCX6flR494Fwhfpi2BDQtADsxbfwkORPQo4J0ROskCQW7vUXcZYUxHjHRppE4Z8CtZF=w1206-h1484-s-no?authuser=0',
        photoAltText:
          'Blue, red, brown and green printed cotton square fabric bookmarks spread out on a green fabric background. Blue fabric triangle book mark wrapped around the upper corner of a book in the lower left corner of the photo.',
        id: 'bookmarks-1',
        description: 'Bookmarks, 2023 - gifted to cast members from Sandwiches',
      },
      {
        artPhoto:
          'https://lh3.googleusercontent.com/pw/AP1GczOr0bz1PLaFx7iWMMzpKA2Jhdww0lrkKo0nFfySvU8y0NYMRuheZIOowiqibdWmJPBxUV16Lnfse7RBC451FDzBJ_GhtI7LqAVwHaPSsfxIGiMRG-exgEdSthi2kS-9loyYZk8amTNuyrWrg6njCAEz=w1114-h1484-s-no?authuser=0',
        photoAltText:
          'Red and blue triangular fabric against a grey paper background. Red fabric (on top) has the word “SANDWICHES” embroidered on it.',
        id: 'bookmarks-2',
        description: 'Bookmarks, 2023 - detail',
      },
      {
        artPhoto:
          'https://lh3.googleusercontent.com/pw/AP1GczPjI00a7cRTlF8N4cQYS80GUf1kh6yFcncIQGoujcGkojFhypZtAI3pwPW0QlU0CIsn03RMRyMpZR7iN-J9hRkYxIIkNZ2brgJNeGjcDnczRxXcpgdLoj5wzE0TmwufQlT9yL3i-9Cd2YTSJHOQ1wvJ=w1582-h1484-s-no?authuser=0',
        photoAltText:
          'Photo of a silver tea cozy with light blue trim embroidered with a landscape including a tree, the sun, a lake and rock formations',
        id: 'georgian-bay-dawn',
        description: 'Georgian Bay Dawn (tea cozy), 2023',
      },
      {
        artPhoto:
          'https://lh3.googleusercontent.com/pw/AP1GczMyT4hFmQrq7Ff7motxb_UaFbQMBPQR7fUvFTu2WshSKxuJRjJSaEPqIlysKDcI3PrU-8rdhRZbG9RpQCHzws9Tw6K1TF0i2E4NSCfQFV48VoYjIIPTLpFk0pwPdxsKw2Ked71AMiUWG9kwpxbr5ODZ=w1684-h1272-s-no?authuser=0',
        photoAltText:
          'Photo of a rectangular fabric hand bag with detailed appliqué and embroidery depicting a landscape with a tree, rocks, water and fish in blues, greens, blacks, and orange printed fabrics as well as plain blue fabric depicting the sky.',
        id: 'knitting-bag-1',
        description:
          'Rock Formations Georgian Bay (knitting bag), 2023 - gifted to Miranda Scott',
      },
      {
        artPhoto:
          'https://lh3.googleusercontent.com/pw/AP1GczPaWIt-A3vTVlgEQC955KwNk6IXii-PIhPf5DJZkIM1Gy9mzmkwnr43ymqI1Q_bwT8fJAjNNNgfMGZHTAs6_3kAoXugHm4G4HeptWrYq3KHfDlZqtwiwFGff3UdGSnxzuN_Iev_A02eW6DXMmmC-RUC=w1114-h1484-s-no?authuser=0',
        photoAltText:
          'Photo of light blue and green, black and brown patterned fabric with appliqué and embroidery of clouds, fish and a landscape.',
        id: 'knitting-bag-2',
        description: 'Rock Formations Georgian Bay, 2023 - detail in progress',
      },
      {
        artPhoto:
          'https://lh3.googleusercontent.com/pw/AP1GczNyQGcq7-4_xeM_xAeqesjHFr3J0fHbL4q-R4b2kjTi2YtD2z7IbWoRvVQLgVkHeT6DiprwPnySzpcINJW9UbDyiyoCzBUJzuN2wqlHIDgwNLT9ezXfdMbNcSKxLMjBOVRGY59SLEFrelBy3H2XjqIz=w1114-h1484-s-no?authuser=0',
        photoAltText:
          'Very colourful heavily embroidered vertical rectangle, made up of geometric shapes of different arranged at odd angles. Blues, yellows, browns, reds, pinks with hearts, flowers, landscapes and infinity symbols',
        id: 'mixed-detail',
        description:
          'Crazy Quilt (book cover), 2021 - bartered to Shelley Taaffe Murray',
      },
      {
        artPhoto:
          'https://lh3.googleusercontent.com/pw/AP1GczMu0ud1fGavZRj6hGb8_gUhvd5QHGslYszABySq1E7cJPXplHtySejep847LiQyPKSfLvwprVwvZvTW7SPn3VZ6q-uf9OcPs2LZnTeMlHbLi7thr7ZxC9Jb_UvmJ4b_-IVTbVZ4laPjHEl0iFGd0kJG=w1114-h1484-s-no?authuser=0',
        photoAltText:
          'Photograph of an earring hanger. Thin wire mesh rectangle with pink fabric frame, and wide white ribbon on top against a textured white background with arge silver circular and floral bell shaped earrings hanging.',
        id: 'earring-hanger',
        description: 'Earring Hanger, 2020 - gifted',
      },
    ],
    [],
  );
