import React from 'react';
import { ThemeProvider } from '@emotion/react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import styled from '@emotion/styled';
import {
  Bio,
  Contact,
  FabricBoxes,
  Landing,
  Practical,
  WallHangings,
  WorkshopsAndCoaching,
} from './pages';
import { dianasTheme } from './styled-theme';
import { Sculpture } from './pages/Sculpture';
import { Header } from './components';

export const App: React.FC = () => {
  return (
    <SiteWrapper>
      <ThemeProvider theme={dianasTheme}>
        <BrowserRouter>
          <Header />
          <Routes>
            <Route path="/" element={<Landing />} />
            <Route path="/fabric-boxes" element={<FabricBoxes />} />
            <Route path="/sculpture" element={<Sculpture />} />
            <Route path="/wall-hangings" element={<WallHangings />} />
            <Route path="/practical" element={<Practical />} />
            {/* <Route path="/poetry-and-stories" element={<PoetryAndStories />} /> */}
            <Route
              path="/workshops-and-coaching"
              element={<WorkshopsAndCoaching />}
            />
            <Route path="/bio" element={<Bio />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </SiteWrapper>
  );
};

export default App;

const SiteWrapper = styled.div`
  display: grid;
  min-height: 100vh;
`;
