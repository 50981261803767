import React from 'react';
import { PageFooter, Gallery, PageWrapper } from '../components';
import { StyledH2, StyledSpan } from './pages.styled';
import { PracticalArtData } from '../assets/data';

export const Practical = () => {
  return (
    <PageWrapper>
      <StyledH2>
        <StyledSpan> Practical Art and Gifts </StyledSpan>
      </StyledH2>
      <Gallery data={PracticalArtData} />
      <PageFooter />
    </PageWrapper>
  );
};
