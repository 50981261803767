import React from 'react';
import { PageWrapper } from '../components';
import {
  Copyright,
  DividingLine,
  ParagraphBreak,
  StyledH2,
  StyledParagraph,
  StyledSpan,
} from './pages.styled';
import bioBackground from '../assets/backgrounds/bio.png';

export const Bio = () => {
  return (
    <PageWrapper bgImg={bioBackground as string}>
      <StyledH2>
        <StyledSpan> Bio </StyledSpan>
      </StyledH2>
      <StyledParagraph>
        I learned my art through a lifetime of practice, combining many crafts,
        including, but not limited to; hand and machine embroidery, knitting,
        macrame, crocheting, beading, and weaving. Combining these skills with
        my own creativity has allowed me to speak to my human experience,
        frustrations, and passions, as well as allowing me to connect with and
        echo nature. My art is what has sustained me and lifted me through the
        many challenges, changes, traumas and joys of my life.
      </StyledParagraph>
      <ParagraphBreak />
      <StyledParagraph>
        It’s very important for me to make art that has a function and/or home.
        I create works only if there is a wall on which to hang it, a babe to
        sleep under it, an arm to carry it, or a soul to appreciate it. And
        sometimes just to heal my own soul.
      </StyledParagraph>
      <DividingLine />
      <StyledParagraph>
        Diana was born in Toronto and grew up in Dorval, Quebec. At the age of
        11 she was shuffled around between various places including Woodstock
        and London On. Her childhood was informed by the chaotic environment
        created by her borderline mother. In the wake of that chaos, she found
        an escape in fiber/fabric construction, crafting and theater. Her
        formative years were filled with self-initiated creation, including wall
        hanging and embroidery projects, marionette construction, doll making,
        clothes and curtain construction, and in her final year of highschool,
        the production of the school musical Annie Get Your Gun.
      </StyledParagraph>
      <ParagraphBreak />
      <StyledParagraph>
        Diana was married in her early twenties. By the time she was forty, she
        was raising three children alone. Birthing, caring for, and raising her
        children has been the most powerfully important thing she has
        accomplished, and was an early life goal. Her children are doing
        important work in their lives and Diana takes great pride in their
        accomplishments. Emma, the oldest, is an international teacher and
        mother to two boys; Cassie is a musician, songwriter, teacher and
        climate activist and Luke is a teacher, and is completing his masters
        towards becoming a psychologist. There were many joys and many struggles
        seeing her children into adulthood.
      </StyledParagraph>
      <ParagraphBreak />
      <StyledParagraph>
        Diana retired from teaching with the Upper Grand District School Board
        in 2013 and since then has spent a great deal of time in her
        studio/sewing room creating things, including: clothing, quilts,
        computer bags, knitting bags, hangings and many fabric boxes for loved
        ones and the occasional client. Her fabric boxes have not only been an
        excellent teaching tool, but also served as special gifts for many loved
        ones and now live in homes all over the world.
      </StyledParagraph>
      <ParagraphBreak />
      <StyledParagraph>
        Although she considers herself to be a homebody, she has had the
        opportunity, thanks to loved ones, to travel to many countries. These
        include: Kuwait, Jordan, Italy, Greece, Turkey, China, Korea, Thailand,
        Vietnam, Cambodia, Ireland, Norway, Russia, Majorca, and Germany.
      </StyledParagraph>
      <ParagraphBreak />
      <StyledParagraph>
        With her present husband Ravi, she has been on several cruises and
        visited his family in England, India and Myanmar (formerly Burma). She
        and her husband have attended family weddings in both India and Turkey.
        She believes travel is one of the best educators but at the same time is
        aware that it has very much increased our planet’s rapid transformation
        into a dangerous imbalance.
      </StyledParagraph>
      <ParagraphBreak />
      <StyledParagraph>
        Diana presently resides between her home in Collingwood, where her
        studio lives and her home in Burlington, where her husband practices as
        a physiotherapist and homeopath. In her studio, she is currently working
        on a fiber/fabric series called Studies On Water, using found objects
        from nature. She is an active participant in The Gaslight Theatre Group
        and has recently begun to write her first play. She is also working
        towards publishing her first book of collected poetry and art entitled
        Journeys of a Stitching Heart.
      </StyledParagraph>
      <Copyright />
    </PageWrapper>
  );
};
