import styled from '@emotion/styled';

export const StyledH2 = styled.h2<{ color?: React.CSSProperties['color'] }>`
  color: ${({ color }) => color ?? 'black'};
  font-family: BadScript, sans-serif;
  font-size: clamp(1.4rem, 3vw, 3rem);
  text-align: center;
  margin-bottom: 1.25rem;
`;

export const StyledSpan = styled.span`
  &:before,
  &:after {
    content: '\\00a0\\00a0\\00a0\\00a0';
    text-decoration: line-through;
  }
`;

export const DividingLine = styled.div<{
  margin?: React.CSSProperties['margin'];
}>`
  border-bottom: solid 0.125rem #393939;
  max-width: 60rem;
  margin: 2rem auto 3.5rem auto;

  @media (min-width: 1119px) {
    margin: 3rem auto 3rem auto;
  }
`;

export const StyledParagraph = styled.p<{
  color?: React.CSSProperties['color'];
}>`
  color: ${({ color }) => color ?? 'black'};
  font-size: 1.5rem;
  text-indent: 2rem;
`;

export const ParagraphBreak = styled.div`
  margin: 1rem 0;
`;

export const Copyright = styled.p<{
  color?: React.CSSProperties['color'];
}>`
  color: ${({ color }) => color ?? 'black'};
  font-family: BadScript, sans-serif;
  font-weight: bold;
  width: 100%;
  text-align: center;
  margin-bottom: 3.5rem;
`;
