import styled from '@emotion/styled';
import React from 'react';
import { Link } from 'react-router-dom';
import { breakpoints, dianasTheme } from '../../../styled-theme';
import { InstagramLogo } from '../../icons';

export const Header: React.FC = () => {
  return (
    <HeaderContainer>
      <StyledLink to="/">
        <Heading>Diana Scott Creations</Heading>
      </StyledLink>
      <NavigationContainer>
        <StyledAnchor
          href="https://www.instagram.com/dianascottcreations"
          target="_blank"
          rel="noreferrer">
          <InstagramLogo />
        </StyledAnchor>
      </NavigationContainer>
    </HeaderContainer>
  );
};

const HeaderContainer = styled.div`
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${dianasTheme.backgroundColor};
  width: 100%;
  padding: 0.75rem 2rem 0.5rem 2rem;
  border-bottom: 0.063rem solid darkgray;
  z-index: 10;
`;

const NavigationContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  padding-top: 0.2rem;
`;

const StyledLink = styled(Link)`
  color: black;
  font-family: BadScript, sans-serif;
  font-size: 1.5rem;

  &:hover,
  &:focus {
    text-decoration: none;
  }
`;

const Heading = styled.h1`
  font-family: BadScript, sans-serif;
  font-size: clamp(1.5rem, 2.5vw, 2rem);
  color: #e35129;
`;

const StyledAnchor = styled.a`
  width: 1.5rem;
  @media (min-width: ${breakpoints.xs}) {
    width: 2rem;
  }
`;
