import React from 'react';
import styled from '@emotion/styled';
import { PageWrapper } from '../components';
import { StyledH2, StyledSpan } from './pages.styled';
import workshops from '../assets/backgrounds/workshops.png';

export const WorkshopsAndCoaching = () => {
  return (
    <PageWrapper bgImg={workshops as string}>
      <StyledH2 color="white">
        <StyledSpan> Workshops and Coaching </StyledSpan>
      </StyledH2>
      <Updates color="white">Events and more info coming soon!!!</Updates>
    </PageWrapper>
  );
};

export const Updates = styled.p<{
  color?: React.CSSProperties['color'];
}>`
  color: ${({ color }) => color ?? 'black'};
  font-size: 1.5rem;
  text-align: center;
`;
