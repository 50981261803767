import React, { useEffect, useState } from 'react';
import ImageMapper, { CustomArea } from 'react-img-mapper';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { shuffle } from 'lodash';
import newPortrait from '../assets/photos/new-portrait.png';
import { getWindowDimensions } from '../components/hooks/partials';
import { Loader } from '../utility';
import { breakpoints } from '../styled-theme';

const colors = [
  '#02da4a',
  '#c6d0ca',
  '#051458',
  '#a73f90',
  '#cd4a1e',
  '#f6fd26',
  '#f45858',
];

export const Landing = () => {
  const [isLoading, setIsLoading] = useState(true);
  const shuffleColors = shuffle(colors);

  const navigate = useNavigate();
  const MAP = {
    name: 'hero-map',
    areas: [
      {
        id: 'fabric-boxes',
        title: 'fabric boxes',
        shape: 'poly',
        name: '2',
        fillColor: `${shuffleColors[0]}50`,
        strokeColor: '#FFF',
        lineWidth: 5,
        coords: [
          760, 2150, 785, 2124, 800, 2106, 850, 2060, 879, 2031, 905, 2019, 935,
          2015, 964, 2024, 996, 2042, 1035, 2070, 1064, 2085, 1101, 2071, 1116,
          2046, 1140, 2002, 1146, 1986, 1112, 1995, 1064, 2004, 1046, 1990,
          1028, 1958, 996, 1940, 950, 1934, 912, 1931, 863, 1949, 808, 1968,
          766, 1982, 729, 2015, 704, 2053, 698, 2082, 697, 2111, 698, 2133, 701,
          2145, 714, 2158, 734, 2165, 748, 2160,
        ],
        // polygon: [
        //   [760, 2150],
        //   [785, 2124],
        //   [800, 2106],
        //   [850, 2060],
        //   [879, 2031],
        //   [905, 2019],
        //   [935, 2015],
        //   [964, 2024],
        //   [996, 2042],
        //   [1035, 2070],
        //   [1064, 2085],
        //   [1101, 2071],
        //   [1116, 2046],
        //   [1140, 2002],
        //   [1146, 1986],
        //   [1112, 1995],
        //   [1064, 2004],
        //   [1046, 1990],
        //   [1028, 1958],
        //   [996, 1940],
        //   [950, 1934],
        //   [912, 1931],
        //   [863, 1949],
        //   [808, 1968],
        //   [766, 1982],
        //   [729, 2015],
        //   [704, 2053],
        //   [698, 2082],
        //   [697, 2111],
        //   [698, 2133],
        //   [701, 2145],
        //   [714, 2158],
        //   [734, 2165],
        //   [748, 2160],
        // ],
      },
      {
        id: 'wall-hangings',
        title: 'Wall Hangings',
        shape: 'poly',
        name: '2',
        fillColor: `${shuffleColors[1]}50`,
        strokeColor: '#FFF',
        lineWidth: 5,
        coords: [
          737, 1787, 764, 1816, 806, 1843, 865, 1850, 927, 1818, 975, 1793,
          1031, 1804, 1067, 1833, 1114, 1856, 1155, 1851, 1185, 1840, 1136,
          1816, 1075, 1794, 1045, 1765, 1021, 1742, 995, 1738, 965, 1745, 924,
          1753, 882, 1763, 827, 1758, 782, 1740, 705, 1669,
        ],
        // polygon: [
        //   [737, 1787],
        //   [764, 1816],
        //   [806, 1843],
        //   [865, 1850],
        //   [927, 1818],
        //   [975, 1793],
        //   [1031, 1804],
        //   [1067, 1833],
        //   [1114, 1856],
        //   [1155, 1851],
        //   [1185, 1840],
        //   [1136, 1816],
        //   [1075, 1794],
        //   [1045, 1765],
        //   [1021, 1742],
        //   [995, 1738],
        //   [965, 1745],
        //   [924, 1753],
        //   [882, 1763],
        //   [827, 1758],
        //   [782, 1740],
        //   [705, 1669],
        // ],
      },
      {
        id: 'sculpture',
        title: 'Sculpture',
        shape: 'poly',
        name: '2',
        fillColor: `${shuffleColors[2]}50`,
        strokeColor: '#FFF',
        lineWidth: 5,
        coords: [
          712, 1461, 713, 1514, 720, 1561, 729, 1592, 756, 1637, 789, 1673, 829,
          1689, 884, 1680, 934, 1644, 979, 1619, 1028, 1622, 978, 1576, 921,
          1578, 882, 1589, 831, 1580, 804, 1543, 772, 1504, 735, 1484, 714,
          1447,
        ],
        // polygon: [
        //   [712, 1461],
        //   [713, 1514],
        //   [720, 1561],
        //   [729, 1592],
        //   [756, 1637],
        //   [789, 1673],
        //   [829, 1689],
        //   [884, 1680],
        //   [934, 1644],
        //   [979, 1619],
        //   [1028, 1622],
        //   [978, 1576],
        //   [921, 1578],
        //   [882, 1589],
        //   [831, 1580],
        //   [804, 1543],
        //   [772, 1504],
        //   [735, 1484],
        //   [714, 1447],
        // ],
      },
      {
        id: 'practical',
        title: 'Practical art and Gifts',
        shape: 'poly',
        name: '2',
        fillColor: `${shuffleColors[3]}50`,
        strokeColor: '#FFF',
        lineWidth: 5,
        coords: [
          946, 1222, 914, 1216, 858, 1220, 824, 1235, 796, 1257, 772, 1300, 776,
          1335, 785, 1371, 800, 1405, 817, 1438, 849, 1466, 891, 1476, 934,
          1469, 987, 1457, 1036, 1452, 1077, 1481, 1107, 1533, 1125, 1576, 1159,
          1598, 1206, 1593, 1238, 1567, 1266, 1533, 1276, 1526, 1232, 1534,
          1213, 1524, 1207, 1501, 1180, 1466, 1155, 1437, 1133, 1399, 1075,
          1386, 1036, 1380, 989, 1378, 922, 1360, 881, 1338, 873, 1299, 897,
          1251, 916, 1231,
        ],
        // polygon: [
        //   [946, 1222],
        //   [914, 1216],
        //   [858, 1220],
        //   [824, 1235],
        //   [796, 1257],
        //   [772, 1300],
        //   [776, 1335],
        //   [785, 1371],
        //   [800, 1405],
        //   [817, 1438],
        //   [849, 1466],
        //   [891, 1476],
        //   [934, 1469],
        //   [987, 1457],
        //   [1036, 1452],
        //   [1077, 1481],
        //   [1107, 1533],
        //   [1125, 1576],
        //   [1159, 1598],
        //   [1206, 1593],
        //   [1238, 1567],
        //   [1266, 1533],
        //   [1276, 1526],
        //   [1232, 1534],
        //   [1213, 1524],
        //   [1207, 1501],
        //   [1180, 1466],
        //   [1155, 1437],
        //   [1133, 1399],
        //   [1075, 1386],
        //   [1036, 1380],
        //   [989, 1378],
        //   [922, 1360],
        //   [881, 1338],
        //   [873, 1299],
        //   [897, 1251],
        //   [916, 1231],
        // ],
      },
      {
        id: 'poetry-and-stories',
        title: 'Poetry and Stories',
        shape: 'poly',
        name: '2',
        fillColor: `${shuffleColors[4]}50`,
        strokeColor: '#FFF',
        lineWidth: 5,
        coords: [
          1079, 1138, 1074, 1161, 1066, 1197, 1057, 1236, 1064, 1274, 1087,
          1314, 1117, 1333, 1155, 1323, 1176, 1321, 1144, 1293, 1136, 1242,
          1149, 1203, 1165, 1153, 1151, 1076, 1152, 1027, 1126, 992, 1072, 953,
          1015, 922, 980, 909, 939, 900, 904, 905, 872, 924, 829, 951, 814, 988,
          806, 1019, 802, 1051, 803, 1069, 822, 1033, 849, 1008, 891, 992, 939,
          995, 983, 1008, 1018, 1027, 1040, 1050, 1061, 1085, 1075, 1117, 1075,
          1119,
        ],
        // polygon: [
        //   [1079, 1138],
        //   [1074, 1161],
        //   [1066, 1197],
        //   [1057, 1236],
        //   [1064, 1274],
        //   [1087, 1314],
        //   [1117, 1333],
        //   [1155, 1323],
        //   [1176, 1321],
        //   [1144, 1293],
        //   [1136, 1242],
        //   [1149, 1203],
        //   [1165, 1153],
        //   [1151, 1076],
        //   [1152, 1027],
        //   [1126, 992],
        //   [1072, 953],
        //   [1015, 922],
        //   [980, 909],
        //   [939, 900],
        //   [904, 905],
        //   [872, 924],
        //   [829, 951],
        //   [814, 988],
        //   [806, 1019],
        //   [802, 1051],
        //   [803, 1069],
        //   [822, 1033],
        //   [849, 1008],
        //   [891, 992],
        //   [939, 995],
        //   [983, 1008],
        //   [1018, 1027],
        //   [1040, 1050],
        //   [1061, 1085],
        //   [1075, 1117],
        //   [1075, 1119],
        // ],
      },
      {
        id: 'workshops-and-coaching',
        title: 'Workshops and Coaching',
        shape: 'poly',
        name: '2',
        fillColor: `${shuffleColors[5]}50`,
        strokeColor: '#FFF',
        lineWidth: 5,

        coords: [
          1520, 1128, 1511, 1074, 1486, 1049, 1453, 1019, 1395, 995, 1332, 1005,
          1287, 1029, 1254, 1079, 1253, 1129, 1270, 1167, 1315, 1192, 1353,
          1214, 1360, 1252, 1351, 1287, 1354, 1313, 1383, 1327, 1431, 1323,
          1469, 1335, 1496, 1345, 1528, 1374, 1554, 1401, 1569, 1445, 1569,
          1489, 1559, 1514, 1550, 1531, 1591, 1498, 1613, 1467, 1619, 1430,
          1613, 1382, 1593, 1324, 1567, 1293, 1510, 1277, 1475, 1265, 1463,
          1260, 1459, 1203, 1431, 1188, 1400, 1170, 1375, 1153, 1363, 1133,
          1365, 1118, 1406, 1100, 1430, 1099, 1453, 1094, 1480, 1098, 1522,
          1123,
        ],
        // polygon: [
        //   [1520, 1128],
        //   [1511, 1074],
        //   [1486, 1049],
        //   [1453, 1019],
        //   [1395, 995],
        //   [1332, 1005],
        //   [1287, 1029],
        //   [1254, 1079],
        //   [1253, 1129],
        //   [1270, 1167],
        //   [1315, 1192],
        //   [1353, 1214],
        //   [1360, 1252],
        //   [1351, 1287],
        //   [1354, 1313],
        //   [1383, 1327],
        //   [1431, 1323],
        //   [1469, 1335],
        //   [1496, 1345],
        //   [1528, 1374],
        //   [1554, 1401],
        //   [1569, 1445],
        //   [1569, 1489],
        //   [1559, 1514],
        //   [1550, 1531],
        //   [1591, 1498],
        //   [1613, 1467],
        //   [1619, 1430],
        //   [1613, 1382],
        //   [1593, 1324],
        //   [1567, 1293],
        //   [1510, 1277],
        //   [1475, 1265],
        //   [1463, 1260],
        //   [1459, 1203],
        //   [1431, 1188],
        //   [1400, 1170],
        //   [1375, 1153],
        //   [1363, 1133],
        //   [1365, 1118],
        //   [1406, 1100],
        //   [1430, 1099],
        //   [1453, 1094],
        //   [1480, 1098],
        //   [1522, 1123],
        // ],
      },
      {
        id: 'contact',
        title: 'Contact',
        shape: 'poly',
        name: '2',
        fillColor: `${shuffleColors[6]}50`,
        strokeColor: '#FFF',
        lineWidth: 5,
        coords: [
          1593, 1507, 1633, 1455, 1675, 1442, 1730, 1456, 1773, 1492, 1812,
          1512, 1830, 1513, 1839, 1506, 1842, 1548, 1829, 1575, 1797, 1587,
          1752, 1573, 1702, 1544, 1660, 1529, 1620, 1527, 1584, 1529, 1563,
          1533, 1584, 1515,
        ],
        // polygon: [
        //   [1593, 1507],
        //   [1633, 1455],
        //   [1675, 1442],
        //   [1730, 1456],
        //   [1773, 1492],
        //   [1812, 1512],
        //   [1830, 1513],
        //   [1839, 1506],
        //   [1842, 1548],
        //   [1829, 1575],
        //   [1797, 1587],
        //   [1752, 1573],
        //   [1702, 1544],
        //   [1660, 1529],
        //   [1620, 1527],
        //   [1584, 1529],
        //   [1563, 1533],
        //   [1584, 1515],
        // ],
      },
      {
        id: 'bio',
        title: 'bio',
        shape: 'poly',
        name: '2',
        fillColor: `${shuffleColors[7]}50`,
        strokeColor: '#FFF',
        lineWidth: 5,
        coords: [
          1750, 1146, 1730, 1147, 1702, 1159, 1671, 1178, 1648, 1198, 1637,
          1217, 1644, 1239, 1656, 1257, 1673, 1269, 1704, 1273, 1659, 1270,
          1625, 1261, 1595, 1240, 1581, 1206, 1604, 1151, 1636, 1117, 1664,
          1093, 1701, 1077, 1743, 1073, 1778, 1080, 1808, 1097, 1824, 1124,
          1831, 1154, 1831, 1183, 1825, 1192, 1811, 1159, 1795, 1146, 1769,
          1143, 1748, 1145,
        ],
        // polygon: [
        //   [1750, 1146],
        //   [1730, 1147],
        //   [1702, 1159],
        //   [1671, 1178],
        //   [1648, 1198],
        //   [1637, 1217],
        //   [1644, 1239],
        //   [1656, 1257],
        //   [1673, 1269],
        //   [1704, 1273],
        //   [1659, 1270],
        //   [1625, 1261],
        //   [1595, 1240],
        //   [1581, 1206],
        //   [1604, 1151],
        //   [1636, 1117],
        //   [1664, 1093],
        //   [1701, 1077],
        //   [1743, 1073],
        //   [1778, 1080],
        //   [1808, 1097],
        //   [1824, 1124],
        //   [1831, 1154],
        //   [1831, 1183],
        //   [1825, 1192],
        //   [1811, 1159],
        //   [1795, 1146],
        //   [1769, 1143],
        //   [1748, 1145],
        // ]
      },
    ],
  };

  const useWindowDimensions = () => {
    const [windowDimensions, setWindowDimensions] = useState(
      getWindowDimensions(),
    );
    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);
    return windowDimensions;
  };

  const { width } = useWindowDimensions();

  // const parentWidthCoefficient = 1.1; // experiment with this val

  const parentWidthCoefficient = 1.1; // this works

  const parentWidth = width + 300 * parentWidthCoefficient; // this works

  // const parentWidth = width + 550; // this was the target

  const handleLink = (e: CustomArea) => {
    const { id } = e;
    const path = id ?? '';

    if (id === 'poetry-and-stories') {
      window.location.assign(
        'https://www.youtube.com/channel/UCuxKlbbK0gH-mkSwv5z5gOA',
      );
    } else if (id === 'contact') {
      window.location.assign('https://www.instagram.com/dianascottcreations/');
    } else {
      navigate(`/${path}`);
    }
  };

  const handleLoading = () => {
    setIsLoading(false);
  };

  return (
    <ImageMapperContainer>
      {isLoading && <Loader size="lg" />}
      <ImageMapperWrapper>
        <ImageMapper
          src={newPortrait as string}
          onClick={e => handleLink(e)}
          map={MAP}
          width={width}
          parentWidth={parentWidth}
          responsive
          onLoad={handleLoading}
        />
      </ImageMapperWrapper>
    </ImageMapperContainer>
  );
};

const ImageMapperContainer = styled.section`
  position: relative;
  overflow-x: hidden;
  height: 100vh;
  width: 100%;
`;

const ImageMapperWrapper = styled.div`
  position: absolute;
  top: -120px;
  right: -150px;

  /* TODO: can this be smoother?   // 365 -> 2000 */
  @media (min-width: ${breakpoints.xxs}) {
    top: -150px;
    right: -140px;
  }
  @media (min-width: ${breakpoints.xs}) {
    top: -180px;
    right: -140px;
  }
  @media (min-width: ${breakpoints.sm}) {
    top: -200px;
    right: -140px;
  }
  @media (min-width: ${breakpoints.md}) {
    top: -280px;
    right: -130px;
  }
  @media (min-width: ${breakpoints.xmd}) {
    top: -360px;
    right: -130px;
  }
  @media (min-width: ${breakpoints.xl}) {
    top: -460px;
    right: -120px;
  }
  @media (min-width: ${breakpoints.xxl}) {
    top: -520px;
    right: -120px;
  }
  @media (min-width: ${breakpoints.xxxl}) {
    top: -600px;
    right: -140px;
  }
`;
