import React from 'react';
import { PageFooter, Gallery, PageWrapper } from '../components';
import { StyledH2, StyledSpan } from './pages.styled';
import { WallHangingData } from '../assets/data';

export const WallHangings = () => {
  return (
    <PageWrapper>
      <StyledH2>
        <StyledSpan> Wall Hangings </StyledSpan>
      </StyledH2>
      <Gallery data={WallHangingData} />
      <PageFooter />
    </PageWrapper>
  );
};
