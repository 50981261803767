import React, { useState } from 'react';
import { Description, GalleryWrapper, ImageContainer } from './gallery.styled';
import { ImageComponent } from './paritals';

interface GalleryData {
  artPhoto: string;
  photoAltText: string;
  id: string;
  description: string;
}

interface GalleryProps {
  data: () => GalleryData[];
}

export const Gallery: React.FC<GalleryProps> = ({ data }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const photos = data();

  return (
    <GalleryWrapper>
      {photos.map(photo => {
        const { artPhoto, photoAltText, id, description } = photo;

        return (
          <ImageContainer key={id}>
            <ImageComponent
              artPhoto={artPhoto}
              photoAltText={photoAltText}
              isLoaded={isLoaded}
              setIsLoaded={setIsLoaded}
            />
            <Description isLoaded={isLoaded}>{description}</Description>
          </ImageContainer>
        );
      })}
    </GalleryWrapper>
  );
};
